import * as React from 'react';
import { Link } from 'gatsby';
import { useState } from 'react';
// import Navigation from './nav';
// import { useCategories } from '../hooks/useCategories';

const Header = ({ siteTitle = '' }) => {
  const [isExpanded, toggleExpansion] = useState(false);
  // const categories = useCategories();

  return (
    <header className='bg-gray-900'>
      <div className='flex flex-wrap items-center justify-between max-w-4xl p-4 mx-auto md:p-8'>
        <Link to='/'>
          <h1 className='flex items-center text-white no-underline'>
            <svg
              fill='none'
              className='w-12 h-12 md:mr-8'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 64 64'
            >
              <circle cx='32' cy='32' r='32' fill='#fff' />
              <path
                d='M20.04 13.08v12.384c3.2 0 5.344-.464 6.432-1.392 1.088-.928 1.632-2.48 1.632-4.656 0-2.176-.56-3.776-1.68-4.8-1.12-1.024-3.248-1.536-6.384-1.536Zm18.864 26.4h-2.112c-4 0-6.784-.864-8.352-2.592-1.248-1.344-2.096-3.12-2.544-5.328-.192-.928-.4-1.776-.624-2.544-.512-1.728-1.552-2.592-3.12-2.592H20.04V38.04h2.4V39h-12v-.96h2.4V13.08h-2.4v-.96h12.624c8.16 0 12.24 2.384 12.24 7.152 0 1.888-.688 3.424-2.064 4.608-1.376 1.184-3.136 1.776-5.28 1.776v.336c3.2 0 5.424 2.112 6.672 6.336.64 2.24 1.28 3.84 1.92 4.8.672.928 1.456 1.392 2.352 1.392v.96Z'
                fill='#000'
              />
              <path
                d='M54.384 49.04V50H41.76v-.96h2.832l-3.216-6.672H31.68l-2.784 6.672h3.792V50H24.48v-.96h3.216l10.992-26.4h1.2l12.192 26.4h2.304Zm-22.272-7.68h8.784l-4.704-9.792-4.08 9.792Z'
                fill='#000'
              />
              <path d='m36 32 5 9.5h-9l4-9.5Z' fill='#fff' />
            </svg>
          </h1>
        </Link>

        {/* <Navigation categories={categories} /> */}

        <button
          className='items-center block px-3 py-2 text-white border border-white rounded md:hidden'
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className='w-3 h-3 fill-current'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <title>Menu</title>
            <path d='M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z' />
          </svg>
        </button>

        <nav
          className={`${isExpanded ? `block` : `hidden`} md:block md:items-center w-full md:w-auto`}
        >
          {[
            {
              route: `/minor`,
              title: `My Minor`,
            },
            {
              route: `/contact`,
              title: `Contact`,
            },
          ].map((link) => (
            <Link
              className='block mt-4 text-white no-underline md:inline-block md:mt-0 md:ml-6'
              activeClassName='font-bold'
              key={link.title}
              to={link.route}
            >
              {link.title}
            </Link>
          ))}
        </nav>
      </div>
    </header>
  );
};

export default Header;
