import React from 'react';

import Header from '../components/header';
import Seo from '../components/seo';

const Layout = ({ seo, children }) => {
  return (
    <div className='flex flex-col min-h-screen font-sans text-white bg-gray-900'>
      <Seo seo={seo} />
      <Header siteTitle={seo?.metaTitle || `Title`} />

      <main className='flex-1 w-full max-w-4xl px-4 py-8 mx-auto md:px-8 md:py-14'>{children}</main>

      <footer className='bg-gray-900'>
        <nav className='flex justify-between max-w-4xl p-4 mx-auto text-sm md:p-8'>
          <p className='text-white'>
            Created by{` `}
            <span className='font-bold no-underline'>Roy Appeldoorn</span>
          </p>

          <p>
            <a
              className='font-bold text-white no-underline'
              href='https://github.com/RoyAppeldoorn'
              target='_blank'
              rel='noopener noreferrer'
            >
              GitHub
            </a>
          </p>
        </nav>
      </footer>
    </div>
  );
};

export default Layout;
