import { useStaticQuery, graphql } from 'gatsby';

export const useMetadata = () => {
  const { strapiGlobal } = useStaticQuery(
    graphql`
      query SEO {
        strapiGlobal {
          siteName
          favicon {
            localFile {
              publicURL
            }
          }
          defaultSeo {
            metaTitle
            metaDescription
          }
        }
      }
    `,
  );

  return strapiGlobal;
};
