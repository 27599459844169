import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useMetadata } from '../hooks/useMetadata';

const Seo = ({ seo = {} }) => {
  const { defaultSeo, siteName, favicon } = useMetadata();
  const fullSeo = { ...defaultSeo, ...seo };

  const metaTags = () => {
    const tags = [];

    if (fullSeo.metaTitle) {
      tags.push(
        {
          property: 'og:title',
          content: fullSeo.metaTitle,
        },
        {
          name: 'twitter:title',
          content: fullSeo.metaTitle,
        },
      );
    }
    if (fullSeo.metaDescription) {
      tags.push(
        {
          name: 'description',
          content: fullSeo.metaDescription,
        },
        {
          property: 'og:description',
          content: fullSeo.metaDescription,
        },
        {
          name: 'twitter:description',
          content: fullSeo.metaDescription,
        },
      );
    }
    if (fullSeo.article) {
      tags.push({
        property: 'og:type',
        content: 'article',
      });
    }
    tags.push({ name: 'twitter:card', content: 'summary_large_image' });

    return tags;
  };

  return (
    <Helmet
      title={fullSeo.metaTitle}
      titleTemplate={`%s - ${siteName}`}
      meta={metaTags()}
      link={[
        {
          rel: 'icon',
          href: favicon.publicURL,
        },
      ]}
    />
  );
};

export default Seo;
